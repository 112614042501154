import React from 'react'
import PropTypes from 'prop-types'
import { styles } from '../../utils/styles'

const Gallery = (props) => {
  const { galleryData } = props
  return (
    <div style={styles.galleryContainer}>
      <div style={styles.bigImageContainer}>
        <img
          src={galleryData.bigImage}
          alt="Gallery1"
          style={styles.image}
          className="hover-image"
        />
      </div>
      <div style={styles.smallImagesContainer}>
        {galleryData.smallImages.map((imgSrc, index) => (
          <img
            key={index}
            src={imgSrc}
            alt={`Gallery${index + 2}`}
            style={styles.image}
            className="hover-image"
          />
        ))}
      </div>
    </div>
  )
}

Gallery.propTypes = {
  galleryData: PropTypes.object,
}

Gallery.defaultProps = {
  galleryData: null,
}

export { Gallery }
