/* eslint-disable no-param-reassign */
import { createStore } from 'react-antd-utils'

const storeOptions = { name: 'navbarStore', devtools: window.ISDEV }

const useNavbarStore = createStore(
  (set) => ({
    isOpen: true,

    setisOpen: (flag) => {
      set((state) => {
        state.isOpen = flag
      })
    },
  }),
  storeOptions
)

class NavbarContext {
  static useStore = useNavbarStore

  static getNavData() {
    const status = useNavbarStore.getState().isOpen
    return status
  }

  static setNavData(val) {
    useNavbarStore.getState().setisOpen(val)
  }

  static getStore() {
    return useNavbarStore
  }
}

export { NavbarContext }
