import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'

const CategoryScroll = (props) => {
  const { containerRef } = props

  const [showScrollButtons, setShowScrollButtons] = React.useState(false)
  const [rightButtonPosition, setRightButtonPosition] = React.useState(0)
  const [leftButtonPosition, setLeftButtonPosition] = React.useState(0)

  const handleResize = React.useCallback(() => {
    if (containerRef.current) {
      const showButtons = containerRef.current.scrollWidth > containerRef.current.clientWidth
      setShowScrollButtons(showButtons)
      if (showButtons) {
        const buttonPosition = containerRef.current.clientWidth - 50
        setRightButtonPosition(buttonPosition)
        setLeftButtonPosition(0)
      }
    }
  }, [containerRef])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  const handleScrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 200, behavior: 'smooth' })
    }
  }

  const handleScrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -200, behavior: 'smooth' })
    }
  }

  return showScrollButtons ? (
    <>
      <Button
        shape="circle"
        icon={<LeftOutlined />}
        onClick={handleScrollLeft}
        style={{
          position: 'absolute',
          top: '21%',
          marginLeft: '35px',
          width: '40px',
          height: '40px',
          transform: 'translateY(-50%)',
          left: leftButtonPosition,
        }}
      />
      <Button
        shape="circle"
        icon={<RightOutlined />}
        onClick={handleScrollRight}
        style={{
          position: 'absolute',
          top: '21%',
          marginLeft: '30px',
          width: '40px',
          height: '40px',
          transform: 'translateY(-50%)',
          left: rightButtonPosition,
        }}
      />
    </>
  ) : null
}

CategoryScroll.propTypes = {
  containerRef: PropTypes.object.isRequired,
}

CategoryScroll.defaultProps = {
  containerRef: { current: null },
}

export { CategoryScroll }
