import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { Layout, Typography, Menu, Badge, Avatar, Drawer } from 'antd'
import { PRIMARY_BG_COLOR, PRIMARY_BG2_COLOR, PRIMARY_COLOR } from '../utils/constants'
import {
  UpOutlined,
  UserOutlined,
  HeartOutlined,
  ShoppingCartOutlined,
  WalletOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { ContextStores } from '../stores'
import { useSpring, animated } from '@react-spring/web'
import './style.css'
import { CartDrawer } from './cart-drawer'
import { CustomCard } from './custom-card'
import { customDrawerStyles } from './top-drawer-style'

const { Header, Content } = Layout
const WrappedComp = animated(Header)
// const LogoWrapped = animated(Typography.Title)
// const MenuWrapped = animated(Menu)

const navbarContext = ContextStores.navbar

function HomePageHeader() {
  const [drawerVisible, setDrawerVisible] = React.useState(false)
  const navbarStatus = navbarContext.getStore()((state) => state.isOpen)
  const cartContext = ContextStores.cart

  const cartData = cartContext.getStore()((state) => state.cartData)

  const handleDrawerToggle = () => {
    setDrawerVisible((prev) => !prev)
  }

  const menuItems = React.useMemo(
    () => [
      {
        label: '',
        key: 'user',
        icon: (
          <Avatar
            style={{
              background: PRIMARY_COLOR,
            }}
            icon={<UserOutlined style={{ color: 'white' }} />}
            onClick={handleDrawerToggle}
          />
        ),
      },
      {
        label: '',
        key: 'wishlist',
        icon: (
          <Avatar
            style={{
              background: PRIMARY_COLOR,
            }}
            icon={<HeartOutlined style={{ color: 'white' }} />}
          />
        ),
      },
      {
        label: '',
        key: 'cart',
        icon: (
          <Badge
            count={cartData.length}
            classNames={{
              indicator: 'cart_badge',
            }}
          >
            <Avatar
              style={{
                background: PRIMARY_COLOR,
              }}
              icon={<ShoppingCartOutlined />}
            />
          </Badge>
        ),
      },
    ],
    [cartData.length]
  )

  const onClickHeader = React.useCallback(() => {
    navbarContext.setNavData(!navbarStatus)
  }, [navbarStatus])

  const headerProps = useSpring({
    height: navbarStatus ? 70 : 10,
  })

  const logoProps = useSpring({
    opacity: navbarStatus ? 100 : 0,
    config: {
      tension: 210,
      friction: 20,
    },
  })

  const onMenuClick = React.useCallback(
    (e) => {
      if (e.key === 'cart') {
        cartContext.openCart()
      }
    },
    [cartContext]
  )
  React.useEffect(() => {
    const body = document.body
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

    if (drawerVisible) {
      body.style.overflow = 'hidden'
      body.style.paddingRight = `${scrollbarWidth}px`
    } else {
      body.style.overflow = ''
      body.style.paddingRight = ''
    }

    return () => {
      body.style.overflow = ''
      body.style.paddingRight = ''
    }
  }, [drawerVisible])

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
        }}
      >
        <WrappedComp
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            ...headerProps,
            ...logoProps,
          }}
        >
          <Link to="/">
            <Typography.Title
              style={{
                color: 'white',
                margin: 0,
                cursor: 'pointer',
              }}
              level={2}
            >
              <img src="/logo-dark.svg" alt="logo" />
            </Typography.Title>
          </Link>
          <Menu
            mode="horizontal"
            onClick={(event) => onMenuClick(event)}
            style={{ float: 'right', position: 'sticky', background: PRIMARY_BG_COLOR }}
            items={menuItems}
          />
        </WrappedComp>

        <div
          style={{
            height: 18,
            width: 40,
            background: PRIMARY_BG_COLOR,
            color: 'black',
            fontWeight: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            borderBottomLeftRadius: '.5rem',
            // borderBottomRightRadius: '.5rem'
          }}
          onClick={() => onClickHeader()}
        >
          <UpOutlined />
        </div>
      </div>
      {/* <Topnav /> */}
      <Content
        style={{
          padding: '0 24px',
          background: PRIMARY_BG2_COLOR,
          // height: "90vh",
          // overflow: 'hidden'
        }}
      >
        <Outlet />
      </Content>
      <CartDrawer />
      <div id="custom-drawer-container">
        <Drawer
          placement="top"
          styles={customDrawerStyles}
          visible={drawerVisible}
          width={300}
          height={150}
          className="custom-drawer"
          getContainer={() => document.getElementById('custom-drawer-container')}
          onClose={handleDrawerToggle}
        >
          <CustomCard text="Log in / Register" icon={UserOutlined} />
          <CustomCard text="Check Order Status" icon={WalletOutlined} />
          <CustomCard text="Contact us" icon={MessageOutlined} />
          <CustomCard text="FAQ" icon={QuestionCircleOutlined} />
        </Drawer>
      </div>
    </>
  )
}

export { HomePageHeader }
