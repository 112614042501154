import React from 'react'
import PropTypes from 'prop-types'
import { Collapse, Typography } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { styles } from '../../utils/styles'
import { DESCRIPTION_FONT_SIZE, TITLE_LEVEL } from '../../utils/constants'

const { Panel } = Collapse

const ExpandIcon = ({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)

const DescriptionDetails = (props) => {
  const { data, labels } = props
  const [dimensions, setDimensions] = React.useState({
    width: 'N/A',
    height: 'N/A',
    depth: 'N/A',
    colour: 'N/A',
    style: 'N/A',
  })

  const [material, setMaterial] = React.useState({
    construction: 'Unknown',
    finish: 'Unknown',
    quality: 'Unknown',
  })

  React.useEffect(() => {
    const ExtractDescriptionData = (data) => {
      const dimensions = {
        width: data?.args?.width?.value?.toString() ? `${data.args.width.value} cm` : 'N/A',
        height: data?.args?.height?.value?.toString() ? `${data.args.height.value} cm` : 'N/A',
        depth: data?.args?.depth?.value?.toString() ? `${data.args.depth.value} cm` : 'N/A',
        colour: '',
        style: data.args.styles?.value || 'N/A',
      }

      if (data?.args?.Color) {
        const colorData = data.args.Color
        dimensions.colour = colorData.value?.displayName || colorData.value.material?.displayName
      }

      const material = {
        construction: data?.args?.material?.construction || '13 layers of birchwood',
        finish: data?.args?.Finish?.value
          ? `${data.args.Finish.value}: A non-toxic, scratch-resistant finish with exposed, hand-oiled edges`
          : 'Unknown',

        quality: `Our premium ${
          data?.args?.Finish?.value || 'Unknown'
        } is made from 13 layers of carefully selected birchwood. We leave the edges exposed and hand-oiled to showcase the natural grain of the wood.`,
      }

      return { dimensions, material }
    }

    if (data?.args) {
      const { dimensions: extractedDimensions, material: extractedMaterial } =
        ExtractDescriptionData(data)
      setDimensions(extractedDimensions)
      setMaterial(extractedMaterial)
    }
  }, [data])

  const contentData = [
    {
      header: 'Dimensions & details',
      key: 'dimension_panel',
      content: [
        {
          type: 'text',
          properties: {
            value: 'Need help with measuring your space?',
          },
        },
        {
          type: 'specs',
          properties: {
            items: [
              { label: 'Width', value: dimensions.width },
              { label: 'Height', value: dimensions.height },
              { label: 'Depth', value: dimensions.depth },
              { label: 'Colour', value: dimensions.colour },
              { label: 'Style', value: dimensions.style },
            ],
          },
        },
      ],
    },
    {
      header: 'Material',
      key: 'material_panel',
      content: [
        { type: 'labeled', properties: { label: 'Construction', value: material.construction } },
        { type: 'labeled', properties: { label: 'Finish', value: material.finish } },
        { type: 'labeled', properties: { label: 'Quality', value: material.quality } },
      ],
    },
  ]

  const updateContent = (content) =>
    content.map((item) => {
      if (item.type === 'specs') {
        return {
          ...item,
          properties: {
            items: item.properties.items.map((spec) => ({
              ...spec,
              label: labels[spec.label.toLowerCase()] || spec.label,
            })),
          },
        }
      }
      if (item.type === 'labeled') {
        return {
          ...item,
          properties: {
            label: labels[item.properties.label.toLowerCase()] || item.properties.label,
            value: item.properties.value,
          },
        }
      }
      return item
    })

  return (
    <div style={styles.descriptionContainer}>
      <div style={styles.leftColumn}>
        <Typography.Title level={TITLE_LEVEL}>Your design specification</Typography.Title>
      </div>
      <div style={styles.rightColumn}>
        <Collapse
          className="custom-collapse"
          style={{ fontSize: DESCRIPTION_FONT_SIZE }}
          expandIcon={ExpandIcon}
          expandIconPosition="right"
          defaultActiveKey={['dimension_panel']}
        >
          {contentData.map(({ header, key, content }) => (
            <Panel header={<strong>{header}</strong>} key={key}>
              {updateContent(content).map((item, index) => {
                switch (item.type) {
                  case 'text':
                    return <p key={index}>{item.properties.value}</p>
                  case 'specs':
                    return (
                      <div className="specs" key={index}>
                        {item.properties.items.map((spec, specIndex) => (
                          <p key={specIndex}>
                            <strong>{spec.label}:</strong>{' '}
                            <span className="value">{spec.value}</span>
                          </p>
                        ))}
                      </div>
                    )
                  case 'labeled':
                    return (
                      <p key={index}>
                        <strong>{item.properties.label}:</strong>
                        <br />
                        <p>{item.properties.value}</p>
                      </p>
                    )
                  default:
                    return <p key={index}>Unsupported content format</p>
                }
              })}
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  )
}

DescriptionDetails.propTypes = {
  dimensions: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    depth: PropTypes.string,
    colour: PropTypes.string,
    doors: PropTypes.string,
  }).isRequired,
  material: PropTypes.shape({
    construction: PropTypes.string,
    finish: PropTypes.string,
    quality: PropTypes.string,
  }).isRequired,
  labels: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    depth: PropTypes.string,
    colour: PropTypes.string,
    doors: PropTypes.string,
    construction: PropTypes.string,
    finish: PropTypes.string,
    quality: PropTypes.string,
  }).isRequired,
}

DescriptionDetails.defaultProps = {
  dimensions: {},
  material: {},
  labels: {},
}
export { DescriptionDetails }
