/* eslint-disable no-param-reassign */
import { createStore } from 'react-antd-utils'

const storeOptions = { name: 'navbarStore', devtools: window.ISDEV }

const useCartStore = createStore(
  (set, get) => ({
    cartData: [],
    cartOpen: false,

    addData: (obj) => {
      set((state) => {
        const prevData = get().cartData
        const tempArr = [...prevData, { ...obj, amount: obj?.amount ?? 1 }]

        state.cartData = [...tempArr]
      })
    },

    removeData: (id) => {
      set((state) => {
        const prevData = get().cartData
        const updated = prevData.filter((_, index) => index !== id)
        state.cartData = [...updated]
      })
    },

    removeAddData: (value) => {
      set((state) => {
        const prevData = get().cartData
        const ind = prevData.findIndex((i) => i.id === value.id)
        let newData = JSON.parse(JSON.stringify(prevData))
        newData.splice(ind, 1, value)
        state.cartData = [...newData]
      })
    },

    increaseData: (id) => {
      set((state) => {
        let prevData = JSON.parse(JSON.stringify(get().cartData))
        prevData.forEach((x) => {
          if (x.id === id) {
            x.amount += 1
          }
        })
        state.cartData = [...prevData]
      })
    },

    decreaseData: (id) => {
      set((state) => {
        let prevData = JSON.parse(JSON.stringify(get().cartData))
        prevData.forEach((x) => {
          if (x.id === id) {
            if (x.amount > 1) {
              x.amount -= 1
            }
          }
        })
        state.cartData = [...prevData]
      })
    },

    removeAllData: () => {
      set((state) => {
        state.cartData = []
      })
    },

    getData: () => {
      set((state) => {
        return state.cartData
      })
    },

    openCart: () => {
      set((state) => {
        state.cartOpen = true
      })
    },

    closeCart: () => {
      set((state) => {
        state.cartOpen = false
      })
    },
  }),
  storeOptions
)

class CartContext {
  static useStore = useCartStore

  static getCartData() {
    const cartData = useCartStore.getState().cartData
    return cartData
  }

  static addCartData(obj) {
    useCartStore.getState().addData(obj)
  }

  static removeCartData(id) {
    useCartStore.getState().removeData(id)
  }

  static removeAddCartData(value) {
    useCartStore.getState().removeAddData(value)
  }

  static increaseCartData(id) {
    useCartStore.getState().increaseData(id)
  }

  static decreaseCartData(id) {
    useCartStore.getState().decreaseData(id)
  }

  static removeAllCartData(id) {
    useCartStore.getState().removeAllData()
  }

  static openCart() {
    useCartStore.getState().openCart()
  }

  static closeCart() {
    useCartStore.getState().closeCart()
  }

  static getStore() {
    return useCartStore
  }
}

export { CartContext }
