import React from 'react'
import './App.css'
import { ConfigProvider, Layout } from 'antd'
import {
  Route,
  Routes,
  // useNavigate,
} from 'react-router-dom'
import { PRIMARY_BG_COLOR } from './utils/constants'
import { HomePageHeader } from './components/home-page-header'
import { HomePage } from './pages/home-page'
import { CanvasPage } from './pages/canvas-page'

const items = [
  {
    key: '1',
    label: 'Home',
    path: '/',
    element: <HomePage />,
  },
  {
    key: '2',
    label: 'Canvas',
    path: '/canvas/:id',
    element: <CanvasPage />,
  },
]

function App() {
  // const navigate = useNavigate();

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Roboto',
          },
          components: {
            Layout: {
              headerBg: PRIMARY_BG_COLOR,
              itemActiveBg: PRIMARY_BG_COLOR,
            },
          },
        }}
      >
        <Layout>
          <Routes>
            <Route path="/" element={<HomePageHeader items={items} />}>
              {items.map((x) => (
                <Route exact path={x.path} element={x.element} />
              ))}
            </Route>
          </Routes>
          <Layout.Footer
            style={{
              textAlign: 'center',
              height: 50,
            }}
          >
            {/* Prolincur Technologies */}
          </Layout.Footer>
        </Layout>
      </ConfigProvider>
    </div>
  )
}

export default App
