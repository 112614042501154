import React from 'react'
import { CategoryDetail } from './catagory-detail'

const CONTAINER_STYLE = {
  flex: 1,
  height: 'auto',
  width: 'auto',
  marginLeft: '0px',
  marginRight: '0px',
  backgroundColor: 'transparent',
}

const MAIN_STYLE = {
  display: 'flex',
  flexDirection: 'column',
}

function CategoryList(props) {
  return (
    <div style={CONTAINER_STYLE}>
      <div style={MAIN_STYLE}>
        <CategoryDetail {...props} />
      </div>
    </div>
  )
}

export { CategoryList }
