import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import { TITLE_FONT_SIZE } from '../utils/constants'

const CustomCard = (props) => {
  const { text, icon: Icon, onClick } = props
  const [isHovered, setIsHovered] = React.useState(false)

  const cardStyle = {
    marginTop: '20px',
    background: isHovered ? '#edf0f0' : 'rgb(248, 248, 248)',
    fontSize: TITLE_FONT_SIZE,
    cursor: 'pointer',
    width: '250px',
    justifyContent: 'center',
    display: 'flex',
    border: 'none',
    transition: 'background 0.3s ease',
  }

  return (
    <Card
      style={cardStyle}
      onClick={() => onClick(text)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon /> {text}
    </Card>
  )
}

CustomCard.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.elementType,
  onClick: PropTypes.func,
}

CustomCard.defaultProps = {
  onClick: () => {},
}

export { CustomCard }
