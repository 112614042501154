import { Button, Typography } from 'antd'
import React from 'react'
import Iframe from 'react-iframe'
import { useLocation } from 'react-router-dom'
import { data } from '../utils/static-data'
import { ProductDesign } from '../components/product-design'
import { ContextStores } from '../stores'
import { ProductDetails } from '../components/product-detail/product-details'
import { ORANGE_COLOR, TITLE_LEVEL } from '../utils/constants'
import { ShoppingCartOutlined } from '@ant-design/icons'
import { CartDrawer } from '../components/cart-drawer'

function CanvasPage() {
  const [state, setState] = React.useState({})
  const [similarList, setSimilarlist] = React.useState([])
  const [details, setDetails] = React.useState(null)

  let location = useLocation()
  const navbarContext = ContextStores.navbar
  const cartContext = ContextStores.cart
  const cartData = cartContext.getStore()((state) => state.cartData)

  React.useEffect(() => {
    setState(location.state)

    if (location.state) {
      const similarListings = data?.filter(
        (x) => x.type === location.state.type && x.id !== location.state.id
      )
      setSimilarlist(similarListings)
    }
    window.scrollTo(0, 0)
    navbarContext.setNavData(false)
  }, [location, navbarContext])

  React.useEffect(() => {
    const handler = (event) => {
      if (typeof event.data !== 'object') return
      if (!event.data.type) return
      if (event.data.type !== 'message') return
      if (!event.data.message) return

      const details = JSON.parse(event.data.message)
      setDetails(details)

      if (cartData.filter((x) => x.id === details?.id).length) {
        const temp = cartData.filter((x) => x.id === details.id)[0]
        const merged = { ...temp, ...details, image: details.image ?? temp.image }
        cartContext.removeAddCartData(merged)
      }
    }
    window.addEventListener('message', handler)

    return () => window.removeEventListener('message', handler)
  }, [cartContext, cartData])

  const onClickCart = React.useCallback(() => {
    if (!cartData.filter((i) => i.id === state.id).length) {
      cartContext.addCartData(state)
      navbarContext.setNavData(true)
    }
  }, [cartContext, cartData, navbarContext, state])

  if (!state) return null
  return (
    <>
      <div
        style={{
          height: '100%',
          marginTop: '24px',
        }}
      >
        <Iframe
          url={`${state.url}`}
          position="relative"
          width="100%"
          styles={{
            height: 595,
            border: 'none',
            boxShadow: 'rgb(0 0 0) 0px 2px 5px -3px',
          }}
          allowFullScreen
          loading
          scrolling="no"
        />
        {!!details && (
          <Button
            style={{
              float: 'right',
              background: ORANGE_COLOR,
              color: 'white',
              width: '400px',
              borderRadius: '1rem',
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
              marginTop: 10,
              marginBottom: 5,
            }}
            onClick={() => onClickCart()}
          >
            {cartData.filter((i) => i.id === state.id).length > 0 ? (
              'Add to cart'
            ) : (
              <>
                <ShoppingCartOutlined style={{ fontSize: 16, marginRight: 10 }} /> Add to Cart
              </>
            )}
          </Button>
        )}
        <br />
        <br />
        <br />
        <ProductDetails data={details} />
        {similarList.length > 0 && (
          <div style={{ marginBottom: '20px' }}>
            <Typography.Title
              style={{
                color: '#1D1E1F',
                fontWeight: 600,
                letterSpacing: 0,
                lineHeight: 1.4,
                marginTop: '48px',
                cursor: 'pointer',
              }}
              level={TITLE_LEVEL}
            >
              <u>See more options</u>
            </Typography.Title>
            <Typography.Title
              style={{
                color: '#1D1E1F',
                fontWeight: 600,
                letterSpacing: 0,
                lineHeight: 1.4,
              }}
              level={TITLE_LEVEL}
            >
              {' '}
              You might like{' '}
            </Typography.Title>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {similarList.map((item) => {
                return (
                  <div
                    style={{
                      margin: 5,
                    }}
                  >
                    <ProductDesign item={item} />
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
      <CartDrawer />
    </>
  )
}

export { CanvasPage }
