const styles = {
  galleryContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '16px',
    padding: '28px',
  },
  bigImageContainer: {
    flex: 2,
    position: 'relative',
  },
  smallImagesContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    gap: '25px',
    marginLeft: '35px',
  },
  image: {
    width: '100%',
    height: 'auto',
    transition: 'transform 0.3s ease-in-out',
  },
  descriptionContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '20px',
    gap: '20px',
  },
  leftColumn: {
    flex: '1',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  rightColumn: {
    flex: '2',
    textAlign: 'left',
    marginLeft: '15px',
  },
}

export { styles }
