import React from 'react'
import PropTypes from 'prop-types'
import { DESCRIPTION_FONT_SIZE, TITLE_LEVEL } from '../../utils/constants'
import { Typography } from 'antd'

const H1STYLE = {
  // fonSize: TITLE_LEVEL,
  fontWeight: 700,
  letterSpacing: '-.5px',
  lineHeight: 1.2,
}

const PSTYLE = {
  fontSize: DESCRIPTION_FONT_SIZE,
  fontWeight: 400,
  letterSpacing: '-.2px',
  lineHeight: 1.4,
  marginTop: '0px',
  maxWidth: '760px',
}

const CONTAINER_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '100%',
  backgroundColor: 'transparent',
  color: '#484444',
}

function CategoryDescription(props) {
  const { category } = props
  return (
    <div style={CONTAINER_STYLE}>
      <Typography.Title style={H1STYLE} level={TITLE_LEVEL}>
        {category.title}
      </Typography.Title>
      <p style={PSTYLE}>{category.description}</p>
    </div>
  )
}

CategoryDescription.propTypes = {
  category: PropTypes.object,
}

CategoryDescription.defaultProps = {
  category: {},
}

export { CategoryDescription }
