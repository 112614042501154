import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Affix } from 'antd'
import { Gallery } from './gallery'
import { DescriptionDetails } from './description-details'
import { Reviews } from './review'
import { PRIMARY_BG_COLOR } from '../../utils/constants'
import { galleryData, reviewData } from './data'

const ProductDetails = (props) => {
  const { data } = props
  const [currentSection, setCurrentSection] = React.useState('design')
  const galleryRef = React.useRef(null)
  const descriptionRef = React.useRef(null)
  const reviewRef = React.useRef(null)
  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    }

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.id)
        }
      })
    }

    const observer = new IntersectionObserver(callback, options)

    const galleryElement = galleryRef.current
    const descriptionElement = descriptionRef.current
    const reviewElement = reviewRef.current

    if (galleryElement) observer.observe(galleryElement)
    if (descriptionElement) observer.observe(descriptionElement)
    if (reviewElement) observer.observe(reviewElement)

    return () => {
      if (galleryElement) observer.unobserve(galleryElement)
      if (descriptionElement) observer.unobserve(descriptionElement)
      if (reviewElement) observer.unobserve(reviewElement)
    }
  }, [])

  const scrollToSection = (sectionKey) => {
    switch (sectionKey) {
      case 'design':
        window.scrollTo({ top: 0, behavior: 'smooth' })
        break
      case 'gallery':
        galleryRef.current.scrollIntoView({ behavior: 'smooth' })
        break
      case 'description':
        const descriptionElement = descriptionRef.current
        const offset = 80
        const elementPosition = descriptionElement.getBoundingClientRect().top + window.scrollY
        const offsetPosition = elementPosition - offset
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
        break
      case 'review':
        reviewRef.current.scrollIntoView({ behavior: 'smooth' })
        break
      default:
        break
    }
  }

  return (
    <div
      style={{
        background: PRIMARY_BG_COLOR,
        padding: '16px',
        marginTop: '20px',
      }}
    >
      <Affix offsetTop={0}>
        <Menu
          mode="horizontal"
          selectedKeys={[currentSection]}
          onClick={(e) => scrollToSection(e.key)}
          style={{ background: PRIMARY_BG_COLOR }}
        >
          <Menu.Item key="design">Design</Menu.Item>
          <Menu.Item key="gallery">Gallery</Menu.Item>
          <Menu.Item key="description">Description</Menu.Item>
          <Menu.Item key="review">Review</Menu.Item>
        </Menu>
      </Affix>
      <div id="gallery" ref={galleryRef} style={{ marginTop: '16px' }}>
        <Gallery galleryData={galleryData} />
      </div>
      <div id="description" ref={descriptionRef} style={{ marginTop: '16px' }}>
        <DescriptionDetails data={data} />
      </div>
      <div id="review" ref={reviewRef} style={{ marginTop: '16px' }}>
        <Reviews reviewData={reviewData} />
      </div>
    </div>
  )
}

ProductDetails.propTypes = {
  data: PropTypes.object,
  galleryData: PropTypes.shape({
    bigImage: PropTypes.string,
    smallImages: PropTypes.arrayOf(PropTypes.string),
  }),
  descriptionData: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      key: PropTypes.string,
      content: PropTypes.node,
    })
  ),
  reviewData: PropTypes.arrayOf(
    PropTypes.shape({
      imgSrc: PropTypes.string,
      rating: PropTypes.number,
      text: PropTypes.string,
      reviewer: PropTypes.string,
      date: PropTypes.string,
    })
  ),
}

ProductDetails.defaultProps = {
  data: null,
  galleryData: null,
  descriptionData: [],
  reviewData: [],
}

export { ProductDetails }
