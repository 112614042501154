import React from 'react'
import { Divider } from 'antd'
import { ProductDesign } from '../components/product-design'
import { CategoryList, CategoryDescription } from '../components/catagory'
import { data } from '../utils/static-data'
import { catagoryData } from '../utils/catagory-data'

function HomePage() {
  const [cardData, setCardData] = React.useState(data)
  const [category, setCategory] = React.useState(catagoryData?.filter((x) => x.type === 'all')[0])

  const onSelectCatagory = React.useCallback((item) => {
    if (!item || item?.type === 'all') {
      setCardData(data)
    } else {
      const searched = data.filter((x) => item.type.includes(x.type))
      setCardData(searched)
    }
    setCategory(item)
  }, [])

  return (
    <div
      style={{
        marginTop: 24,
      }}
    >
      <CategoryList onSelect={onSelectCatagory} items={catagoryData} />
      <Divider />
      <CategoryDescription category={category} />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {cardData.map((item) => {
          return (
            <div
              style={{
                margin: 5,
              }}
            >
              <ProductDesign item={item} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export { HomePage }
