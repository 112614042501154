import { Button, Col, Drawer, message, Modal, Row, Typography } from 'antd'
import React from 'react'
import { ContextStores } from '../stores'
import {
  DESCRIPTION_FONT_SIZE,
  ORANGE_COLOR,
  PRIMARY_BG_COLOR,
  TITLE_FONT_SIZE,
} from '../utils/constants'
import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined } from '@ant-design/icons'

function CartDrawer() {
  const cartContext = ContextStores.cart
  const cartStatus = cartContext.getStore()((state) => state.cartOpen)
  const cartData = cartContext.getStore()((state) => state.cartData)

  const [deleteModal, setDeleteModal] = React.useState({ status: false, id: null })
  const [checkOut, setCheckOut] = React.useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  const onClose = React.useCallback(() => {
    cartContext.closeCart()
  }, [cartContext])

  const onClickCheckout = React.useCallback(() => {
    setCheckOut(true)
  }, [])

  const onClickDelete = React.useCallback((id) => {
    setDeleteModal({ status: true, id: id })
  }, [])

  const onOkDelete = React.useCallback(() => {
    cartContext.removeCartData(deleteModal.id)
    setDeleteModal({ status: false, id: null })
  }, [cartContext, deleteModal.id])

  const onCheckoutOk = React.useCallback(() => {
    setCheckOut(false)
    cartContext.removeAllCartData()
    messageApi.open({
      type: 'success',
      content: 'Your items has been processed',
    })
  }, [cartContext, messageApi])

  const onClickQuantityChanger = React.useCallback(
    (sign, id) => {
      if (sign === '-') {
        cartContext.decreaseCartData(id)
      } else if (sign === '+') {
        cartContext.increaseCartData(id)
      }
    },
    [cartContext]
  )

  return (
    <>
      <Drawer
        title="Your Cart"
        onClose={onClose}
        open={cartStatus}
        maskClosable={false}
        // placement='left'
      >
        <div
          style={{
            height: '95%',
            overflow: 'auto',
          }}
        >
          {cartData?.map((x, index) => (
            <div
              style={{
                display: 'flex',
                background: PRIMARY_BG_COLOR,
                marginBottom: 10,
              }}
            >
              <img
                src={x.image}
                alt={x.displayName}
                style={{
                  width: 180,
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginLeft: 10,
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography.Title
                    style={{
                      fontSize: TITLE_FONT_SIZE,
                      marginTop: 5,
                    }}
                  >
                    {' '}
                    {x.displayName}{' '}
                  </Typography.Title>
                  <Typography.Text
                    style={{
                      fontSize: DESCRIPTION_FONT_SIZE,
                    }}
                  >
                    {' '}
                    {x.description}{' '}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      fontSize: DESCRIPTION_FONT_SIZE,
                    }}
                  >
                    Type: {x.type}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      fontSize: DESCRIPTION_FONT_SIZE,
                    }}
                  >
                    Size: {x.size}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      fontSize: DESCRIPTION_FONT_SIZE,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Quantity:
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginLeft: 5,
                      }}
                    >
                      <CaretUpOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={() => onClickQuantityChanger('+', x.id)}
                      />
                      {x.amount}
                      <CaretDownOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={() => onClickQuantityChanger('-', x.id)}
                      />
                    </div>
                  </Typography.Text>
                </div>

                <div
                  style={{
                    float: 'right',
                    margin: 10,
                  }}
                >
                  <DeleteOutlined
                    style={{
                      color: 'red',
                      float: 'right',
                      cursor: 'pointer',
                    }}
                    onClick={() => onClickDelete(index)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        {cartData.length > 0 && (
          <Button
            style={{
              float: 'right',
              background: ORANGE_COLOR,
              color: 'white',
              width: '100%',
              borderRadius: '1rem',
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
              marginTop: 10,
            }}
            onClick={() => onClickCheckout()}
          >
            Checkout
          </Button>
        )}
      </Drawer>

      {/* delete */}
      <Modal
        title="Remove item?"
        open={deleteModal.status}
        onOk={onOkDelete}
        onCancel={() => setDeleteModal({ status: false, id: null })}
        maskClosable={false}
        destroyOnClose
      >
        <Typography.Title style={{ fontSize: TITLE_FONT_SIZE }}>
          {' '}
          Are you sure, you want to remove the item from the cart?{' '}
        </Typography.Title>
      </Modal>

      {/* checkout */}
      <Modal
        title="These are your items"
        open={checkOut}
        onOk={() => setCheckOut(false)}
        // onCancel={() => setDeleteModal({ status: false, id: null }) }
        maskClosable={false}
        destroyOnClose
        footer={() => (
          <>
            {' '}
            <Button onClick={() => onCheckoutOk()} type="primary">
              {' '}
              Ok{' '}
            </Button>{' '}
          </>
        )}
        closable={false}
        width="40%"
      >
        <Row gutter={[16, 16]}>
          {cartData?.map((x) => (
            <Col
              span={12}
              style={{
                display: 'flex',
                background: PRIMARY_BG_COLOR,
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography.Title
                  style={{
                    fontSize: TITLE_FONT_SIZE,
                    margin: 0,
                  }}
                  ellipsis
                >
                  {x.displayName}
                </Typography.Title>
                <Typography.Text
                  style={{
                    fontSize: DESCRIPTION_FONT_SIZE,
                  }}
                >
                  {x.size}
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: DESCRIPTION_FONT_SIZE,
                  }}
                >
                  {x.type}
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: DESCRIPTION_FONT_SIZE,
                  }}
                >
                  Quantity: {x.amount}
                </Typography.Text>

                {x?.args && (
                  <>
                    <Typography.Text
                      style={{
                        fontSize: DESCRIPTION_FONT_SIZE,
                      }}
                    >
                      {x.args.Color.data.length} Colors
                    </Typography.Text>

                    <Typography.Text
                      style={{
                        fontSize: DESCRIPTION_FONT_SIZE,
                      }}
                    >
                      {x.args.styles.data.length} Styles
                    </Typography.Text>
                  </>
                )}
              </div>
              <img
                src={x.image}
                alt={x.displayName}
                style={{
                  width: 80,
                }}
              />
            </Col>
          ))}
        </Row>
      </Modal>
      {contextHolder}
    </>
  )
}

export { CartDrawer }
