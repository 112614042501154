export const PRIMARY_BG_COLOR = '#F8F8F8'
export const PRIMARY_BG2_COLOR = '#FFFFFF'
export const PRIMARY_COLOR = '#91B2F1' // "#FF4100";
export const ORANGE_COLOR = '#FF4100'

export const DESCRIPTION_FONT_SIZE = '14px'
export const TITLE_FONT_SIZE = '16px'
export const CARD_META_BG = '#fff'
export const DESCRIPTION_COLOR = 'black'
export const TITLE_LEVEL = 3
