import React from 'react'
import { Card } from 'antd'
import { useSpring, animated } from '@react-spring/web'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { TITLE_FONT_SIZE } from '../utils/constants'

const { Meta } = Card

const CONTAINER_STYLE = {
  display: 'flex',
  flexDirection: 'column',
}

function ProductDesign({ item }) {
  const [active, setActive] = React.useState(false)
  const navigate = useNavigate()

  const props = useSpring({
    opacity: active ? '100%' : '0%',
    top: active ? '50%' : '60%',
  })

  const onClickViewBtn = React.useCallback(
    (item) => {
      navigate(`/canvas/${item.id}`, { state: item })
    },
    [navigate]
  )

  const metaDescription = React.useMemo(() => {
    return (
      <div style={CONTAINER_STYLE}>
        <span
          style={{
            color: item.isTopSeller ? '#BE7958' : '#F3F3F3',
            fontSize: '10px',
            fontWeight: 600,
            lineHeight: 1.4,
            marginBottom: '5px',
          }}
        >
          {'TOP SELLER'}
        </span>
        <span
          style={{
            color: item.make ? '#717173' : '#F3F3F3',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 1.4,
          }}
        >
          {item.make ?? 'Original Classic'}
        </span>
        <span
          style={{ color: '#3A3C3D', fontSize: TITLE_FONT_SIZE, fontWeight: 700, lineHeight: 1.4 }}
        >
          {item.displayName}
        </span>
        <span
          style={{
            color: item.size ? '#7C7D81' : '#F3F3F3',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 1.4,
          }}
        >
          {item.size ?? '40 x 80 cm'}
        </span>
      </div>
    )
  }, [item])

  return (
    <Card
      hoverable
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      style={{
        width: 350,
        position: 'relative',
      }}
      styles={{
        body: {
          background: '#F3F3F3',
        },
      }}
      cover={
        <>
          {item.image ? (
            <img alt={item.name} src={item.image} style={{ height: 340 }} />
          ) : (
            <div
              style={{
                height: 340,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span style={{ fontWeight: 'bold' }}> No Preview </span>
            </div>
          )}
          <animated.div
            style={{
              position: 'absolute',
              left: '30%',
              width: 'fit-content',
              ...props,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                background: 'white',
                width: 'fit-content',
                padding: '9px 35px',
                color: 'black',
                borderRadius: '30px',
                fontWeight: '600',
                boxShadow: 'rgb(0 0 0) 0px 2px 5px -3px',
                zIndex: 100,
              }}
              onClick={() => onClickViewBtn(item)}
            >
              Configure
            </div>
          </animated.div>
        </>
      }
    >
      <Meta title={metaDescription} />
    </Card>
  )
}

ProductDesign.propTypes = {
  item: PropTypes.object,
}

ProductDesign.defaultProps = {
  item: {},
}

export { ProductDesign }
