import sideboard_walnut_197x139 from '../assets/product-images/sideboard_walnut_197x139.png'
import sideboard_oak_236x116 from '../assets/product-images/sideboard_oak_236x116.png'
import sideboard_white_oak_80x108 from '../assets/product-images/sideboard_white_oak_80x108.png'

import bookcase_oak_200x170 from '../assets/product-images/bookcase_oak_200x170.png'
import bookcase_whiteoak_247x187 from '../assets/product-images/bookcase_whiteoak_247x187.png'

import wallstorage_walnut_184x200 from '../assets/product-images/wallstorage_walnut_184x200.png'
import wallstorage_oak_180x126 from '../assets/product-images/wallstorage_oak_180x126.png'
import wallstorage_black_204x136 from '../assets/product-images/wallstorage_black_204x136.png'

import wardrobe_oak_236x360 from '../assets/product-images/wardrobe_oak_236x360.png'

import bedside_table_walnut_138x102 from '../assets/product-images/bedside_table_walnut_138x102.png'
import bedside_table_oak_80x54 from '../assets/product-images/bedside_table_oak_80x54.png'
import bedside_table_black_54x67 from '../assets/product-images/bedside_table_black_54x67.png'

import tvstand_walnut_169x80 from '../assets/product-images/tvstand_walnut_169x80.png'
import tvstand_oak_163x54 from '../assets/product-images/tvstand_oak_163x54.png'
import tvstand_whiteoak_105x49 from '../assets/product-images/tvstand_whiteoak_105x49.png'

import shoerack_oak_175x120 from '../assets/product-images/shoerack_oak_175x120.png'
import shoerack_white_and_red_200x240 from '../assets/product-images/shoerack_white_and_red_200x240.png'

import vinyl_storage_walnut_249x136 from '../assets/product-images/vinyl_storage_walnut_249x136.png'
import vinyl_storage_oak_153x111 from '../assets/product-images/vinyl_storage_oak_153x111.png'
import vinyl_storage_black_155x111 from '../assets/product-images/vinyl_storage_black_155x111.png'
import vinyl_storage_white_123x111 from '../assets/product-images/vinyl_storage_white_123x111.png'

import desk_walnut_160x90 from '../assets/product-images/desk_walnut_160x90.png'
import desk_whiteoak_105x110 from '../assets/product-images/desk_whiteoak_105x110.png'

export const data = [
  {
    displayName: 'Sideboard in walnut',
    description: 'Sideboard in walnut',
    image: sideboard_walnut_197x139,
    type: 'sideboard',
    id: '66a7e3f0ce9c11b66d2a69d4',
    url: 'https://dev.vectraxd.com/published/furniture/66a7e3f0ce9c11b66d2a69d4',
    size: '197 x 139 cm',
    make: 'Organic Classic',
    isTopSeller: true,
  },
  {
    displayName: 'Sideboard in oak with drawers and doors',
    description: 'Sideboard in oak with drawers and doors',
    image: sideboard_oak_236x116,
    type: 'sideboard',
    id: '66a7e42dce9c11b66d2a69d7',
    url: 'https://dev.vectraxd.com/published/furniture/66a7e42dce9c11b66d2a69d7',
    size: '236 x 116 cm',
    make: 'Organic Classic',
    isTopSeller: false,
  },
  {
    displayName: 'Sideboard in white oak with drawers',
    description: 'Sideboard in white oak with drawers',
    image: sideboard_white_oak_80x108,
    type: 'sideboard',
    id: '66b0a8ffce9c11b66d2a8c98',
    url: 'https://dev.vectraxd.com/published/furniture/66b0a8ffce9c11b66d2a8c98',
    size: '80 x 108 cm',
    make: 'Organic Classic',
    isTopSeller: false,
  },

  {
    displayName: 'Bookcase in oak',
    description: 'Bookcase in oak',
    image: bookcase_oak_200x170,
    type: 'bookcase',
    id: '66a7fb85ce9c11b66d2a6c16',
    url: 'https://dev.vectraxd.com/published/furniture/66a7fb85ce9c11b66d2a6c16',
    size: '200 x 170 cm',
    make: 'Organic Classic',
    isTopSeller: true,
  },
  {
    displayName: 'Bookcase in white oak',
    description: 'Bookcase in white oak',
    image: bookcase_whiteoak_247x187,
    type: 'bookcase',
    id: '66a868ddce9c11b66d2a6c81',
    url: 'https://dev.vectraxd.com/published/furniture/66a868ddce9c11b66d2a6c81',
    size: '247 x 187 cm',
    make: 'Organic Classic',
    isTopSeller: true,
  },

  {
    displayName: 'Wall storage un walnut',
    description: 'Wall storage un walnut',
    image: wallstorage_walnut_184x200,
    type: 'wall_storage',
    id: '66a892b5ce9c11b66d2a70cc',
    url: 'https://dev.vectraxd.com/published/furniture/66a892b5ce9c11b66d2a70cc',
    size: '184 x 200 cm',
    make: 'Organic Classic',
    isTopSeller: true,
  },
  {
    displayName: 'Wall storage in oak',
    description: 'Wall storage in oak',
    image: wallstorage_oak_180x126,
    type: 'wall_storage',
    id: '66a89bf1ce9c11b66d2a7190',
    url: 'https://dev.vectraxd.com/published/furniture/66a89bf1ce9c11b66d2a7190',
    size: '180 x 126 cm',
    make: 'Organic Classic',
    isTopSeller: true,
  },
  {
    displayName: 'Wall storage in black grey plywood',
    description: 'Wall storage in black grey plywood',
    image: wallstorage_black_204x136,
    type: 'wall_storage',
    id: '66a8a3ddce9c11b66d2a72a4',
    url: 'https://dev.vectraxd.com/published/furniture/66a8a3ddce9c11b66d2a72a4',
    size: '204 x 136 cm',
    make: 'Organic Modern',
    isTopSeller: false,
  },

  {
    displayName: 'Wardrobe in oak',
    description: 'Wardrobe in oak',
    image: wardrobe_oak_236x360,
    type: 'wardrobe',
    id: '66a8a3ddce9c11b66d2a72a4',
    url: 'https://dev.vectraxd.com/published/furniture/66a8cdc8ce9c11b66d2a73c2',
    size: '236 x 360 cm',
    make: 'Organic Modern',
    isTopSeller: false,
  },

  {
    displayName: 'Bedside table in walnut',
    description: 'Bedside table in walnut',
    image: bedside_table_walnut_138x102,
    type: 'bedside_table',
    id: '66b2128dce9c11b66d2a9b5d',
    url: 'https://dev.vectraxd.com/published/furniture/66b2128dce9c11b66d2a9b5d',
    size: '138 x 102 cm',
    make: 'Organic Modern',
    isTopSeller: true,
  },
  {
    displayName: 'Bedside table in oak',
    description: 'Bedside table in oak',
    image: bedside_table_oak_80x54,
    type: 'bedside_table',
    id: '66b212c5ce9c11b66d2a9b60',
    url: 'https://dev.vectraxd.com/published/furniture/66b212c5ce9c11b66d2a9b60',
    size: '80 x 54 cm',
    make: 'Organic Modern',
    isTopSeller: true,
  },
  {
    displayName: 'Bedside table in grey',
    description: 'Bedside table in grey',
    image: bedside_table_black_54x67,
    type: 'bedside_table',
    id: '66b21305ce9c11b66d2a9b68',
    url: 'https://dev.vectraxd.com/published/furniture/66b21305ce9c11b66d2a9b68',
    size: '54 x 67 cm',
    make: 'Organic Modern',
    isTopSeller: true,
  },

  {
    displayName: 'TV stand in walnut',
    description: 'TV stand in walnut',
    image: tvstand_walnut_169x80,
    type: 'tv_stand',
    id: '66ab261bce9c11b66d2a7e52',
    url: 'https://dev.vectraxd.com/published/furniture/66ab261bce9c11b66d2a7e52',
    size: '169 x 80 cm',
    make: 'Organic Modern',
    isTopSeller: true,
  },
  {
    displayName: 'TV stand in oak',
    description: 'TV stand in walnut',
    image: tvstand_oak_163x54,
    type: 'tv_stand',
    id: '66ab286fce9c11b66d2a7e85',
    url: 'https://dev.vectraxd.com/published/furniture/66ab286fce9c11b66d2a7e85',
    size: '163 x 54 cm',
    make: 'Organic Modern',
    isTopSeller: true,
  },
  {
    displayName: 'TV stand in white oak',
    description: 'TV stand in whirte oak',
    image: tvstand_whiteoak_105x49,
    type: 'tv_stand',
    id: '66ab28aace9c11b66d2a7e88',
    url: 'https://dev.vectraxd.com/published/furniture/66ab28aace9c11b66d2a7e88',
    size: '105 x 49 cm',
    make: 'Organic Modern',
    isTopSeller: true,
  },

  {
    displayName: 'Shoe rack in oak',
    description: 'Shoe rack in oak',
    image: shoerack_oak_175x120,
    type: 'shoe_rack',
    id: '66a9d4e1ce9c11b66d2a765d',
    url: 'https://dev.vectraxd.com/published/furniture/66a9d4e1ce9c11b66d2a765d',
    size: '175 x 120 cm',
    make: 'Organic Modern',
    isTopSeller: true,
  },
  {
    displayName: 'Shoe rack in white and red color',
    description: 'Shoe rack in white and red color',
    image: shoerack_white_and_red_200x240,
    type: 'shoe_rack',
    id: '66aa074cce9c11b66d2a787e',
    url: 'https://dev.vectraxd.com/published/furniture/66aa074cce9c11b66d2a787e',
    size: '200 x 240 cm',
    make: 'Organic Modern',
    isTopSeller: true,
  },

  {
    displayName: 'Vinyl storage in walnut',
    description: 'Vinyl storage in walnut',
    image: vinyl_storage_walnut_249x136,
    type: 'vinyl_storage',
    id: '66ab3835ce9c11b66d2a814d',
    url: 'https://dev.vectraxd.com/published/furniture/66ab3835ce9c11b66d2a814d',
    size: '249 x 136 cm',
    make: 'Organic Classic',
    isTopSeller: true,
  },
  {
    displayName: 'Vinyl storage in oak',
    description: 'Vinyl storage in oak',
    image: vinyl_storage_oak_153x111,
    type: 'vinyl_storage',
    id: '66ab31dcce9c11b66d2a7f5a',
    url: 'https://dev.vectraxd.com/published/furniture/66ab31dcce9c11b66d2a7f5a',
    size: '153 x 111 cm',
    make: 'Organic Modern',
    isTopSeller: true,
  },
  {
    displayName: 'Vinyl storage in white red color',
    description: 'Vinyl storage in white red color',
    image: vinyl_storage_white_123x111,
    type: 'vinyl_storage',
    id: '66ab2eb6ce9c11b66d2a7f15',
    url: 'https://dev.vectraxd.com/published/furniture/66ab2eb6ce9c11b66d2a7f15',
    size: '123 x 111 cm',
    make: 'Organic Modern',
    isTopSeller: true,
  },
  {
    displayName: 'Vinyl storage in black plywood color',
    description: 'Vinyl storage in black plywood color',
    image: vinyl_storage_black_155x111,
    type: 'vinyl_storage',
    id: '66ab3b10ce9c11b66d2a81a8',
    url: 'https://dev.vectraxd.com/published/furniture/66ab3b10ce9c11b66d2a81a8',
    size: '155 x 111 cm',
    make: 'Organic Modern',
    isTopSeller: true,
  },

  {
    displayName: 'Desk in walnut',
    description: 'Desk in walnut',
    image: desk_walnut_160x90,
    type: 'desk',
    id: '66ab46c0ce9c11b66d2a8272',
    url: 'https://dev.vectraxd.com/published/furniture/66ab46c0ce9c11b66d2a8272',
    size: '160 x 90 cm',
    make: 'Organic Classic',
    isTopSeller: true,
  },
  {
    displayName: 'Desk in white oak',
    description: 'Desk in white oak',
    image: desk_whiteoak_105x110,
    type: 'desk',
    id: '66ab2eebce9c11b66d2a7f18',
    url: 'https://dev.vectraxd.com/published/furniture/66ab2eebce9c11b66d2a7f18',
    size: '105 x 110 cm',
    make: 'Organic Modern',
    isTopSeller: true,
  },
]
