import all_productsPng from '../assets/catagories/all_products.png'
import sideboardPng from '../assets/catagories/sideboard/sideboard.png'
import bookcasePng from '../assets/catagories/bookcase/bookcase.png'
import bedsideTablePng from '../assets/catagories/bedside_table/bedsidetable.png'
import wardrobePng from '../assets/catagories/wardrobe/wardrobe.png'
import wallstorage from '../assets/catagories/wall_storage/wallstorage.png'
import shoerackPng from '../assets/catagories/shoe_rack/shoerack.png'
import tvstandPng from '../assets/catagories/tv_stand/tvstand.png'
import vinylStoragePng from '../assets/catagories/vinyl_storage/vinylstorage.png'
import deskPng from '../assets/catagories/desk/desk.png'

export const catagoryData = [
  {
    title: 'All products',
    image: all_productsPng,
    type: 'all',
    description:
      'Our thoughtfully designed storage furniture encourages effortless organisation and a more harmonious living.',
  },
  {
    title: 'Sideboards',
    image: sideboardPng,
    type: 'sideboard',
    description:
      'It only takes a few clicks to design a unique sideboard that fits your space and your style. Customize the size, color, and many other details that will make you create the perfect piece of furniture for you.',
  },
  {
    title: 'Bookcases',
    image: bookcasePng,
    type: 'bookcase',
    description: 'Up to 403 cm high, so that every story can find its place.',
  },
  {
    title: 'Wall Storage',
    image: wallstorage,
    type: 'wall_storage',
    description:
      'Make more space at home by designing your ideal Wall Storage. Customise size and colour, plus add open or closed segments to show off objects and keep extra storage tucked away.',
  },
  {
    title: 'Wardrobes',
    image: wardrobePng,
    type: 'wardrobe',
    description:
      'With the possibility to create a wardrobe as big as 3,6m in height and 6m in width, your storage possibilities are endless.',
  },
  {
    title: 'Bedside Tables',
    image: bedsideTablePng,
    type: 'bedside_table',
    description:
      'With a customisable width starting at 30cm, the Bedside Table fits the smallest of bedrooms whilst offering enough space to keep all your most necessary items at arm’s reach.',
  },
  {
    title: 'TV Stands',
    image: tvstandPng,
    type: 'tv_stand',
    description:
      'Starting from 23cm in height, the customizable TV Stand is the real star in the room.',
  },
  //   {
  //     title: "Chest of Drawers",
  //     image: "https://res.cloudinary.com/cstm/image/upload/v1/common/menu/categories/chest/A.png",
  //     type: "sideboard",
  //     description: "Looking for a made to measure chest of drawers? easy configurator lets you design the perfect piece to fit your space and style, with a range of sizes and colours."
  //   },
  {
    title: 'Shoe Racks',
    image: shoerackPng,
    type: 'shoe_rack',
    description:
      'With this furniture only 32 cm deep, and especially suitable for furnishing entrances or corridors, there will be no obstacles in your way.',
  },
  {
    title: 'Vinyl Storage',
    image: vinylStoragePng,
    type: 'vinyl_storage',
    description:
      'Con scomparti profondi 40 cm e ripiani di tre altezze perfettamente proporzionate ai tuoi dischi in vinile, la tua collezione sarà sempre al sicuro in un luogo raffinato.',
  },
  {
    title: 'Desks',
    image: deskPng,
    type: 'desk',
    description:
      'Tackle any task with a stylish desk that makes the most of your space, and your time.',
  },
]
