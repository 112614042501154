const customDrawerStyles = {
  drawer: {
    zIndex: 0,
  },
  body: {
    padding: '10px 0px 10px 10px',
  },
  mask: {
    marginTop: '90px',
  },
  content: {
    marginTop: '90px',
  },
  header: {
    display: 'none',
  },
}

export { customDrawerStyles }
