import React from 'react'
import PropTypes from 'prop-types'
import { Rate, Drawer } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { TITLE_FONT_SIZE } from '../../utils/constants'

const Reviews = (props) => {
  const { reviewData } = props
  const [drawerVisible, setDrawerVisible] = React.useState(false)
  const [currentReview, setCurrentReview] = React.useState(null)
  const [visibleReviews, setVisibleReviews] = React.useState(4)

  const showDrawer = (review) => {
    setCurrentReview(review)
    setDrawerVisible(true)
  }

  const onClose = () => {
    setDrawerVisible(false)
    setCurrentReview(null)
  }

  const showMoreReviews = () => {
    setVisibleReviews(reviewData.length)
  }

  return (
    <div className="centeredContainer">
      <div className="reviewContainer">
        <div className="leftColumn">
          <h1 style={{ fontSize: TITLE_FONT_SIZE }}>4.9/5</h1>
          <p>Detailed conditions for publishing reviews can be found in our Terms of Service.</p>
        </div>
        <div className="rightColumn">
          {reviewData.slice(0, visibleReviews).map((review, index) => (
            <div key={index} className="reviewItem">
              <div className="reviewContent">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={review.imgSrc} alt={`Reviewer ${index + 1}`} className="reviewImage" />
                  <div style={{ marginLeft: '16px', flex: 1 }}>
                    <p style={{ marginBottom: '8px' }}>{review.text}</p>
                    <Rate disabled defaultValue={review.rating} />
                    <p>
                      <strong>{review.reviewer}</strong>
                      {review.location}
                    </p>
                    <p>{review.date}</p>
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <RightOutlined
                      style={{ cursor: 'pointer' }}
                      onClick={() => showDrawer(review)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Drawer
        title="Detailed Review"
        width={640}
        placement="right"
        onClose={onClose}
        visible={drawerVisible}
      >
        {currentReview && (
          <div className="detailedReviewContent">
            <img src={currentReview.imgSrc} alt="Reviewer" className="reviewImageLarge" />
            <div className="reviewContent">
              <p>{currentReview.text}</p>
              <Rate disabled defaultValue={currentReview.rating} />
              <p>
                <strong>{currentReview.reviewer}</strong>
                {currentReview.location}
              </p>
              <p>{currentReview.date}</p>
            </div>
          </div>
        )}
      </Drawer>
      {visibleReviews < reviewData.length && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <button
            style={{
              display: 'block',
              textAlign: 'center',
              border: 'none',
              cursor: 'pointer',
              background: 'transparent',
              textDecoration: 'underline',
              position: 'relative',
              left: '250px',
            }}
            onClick={showMoreReviews}
          >
            Show more
          </button>
        </div>
      )}
    </div>
  )
}

Reviews.propTypes = {
  reviewData: PropTypes.arrayOf(
    PropTypes.shape({
      imgSrc: PropTypes.string,
      rating: PropTypes.number,
      text: PropTypes.string,
      reviewer: PropTypes.string,
      location: PropTypes.string,
      date: PropTypes.string,
    })
  ),
}

Reviews.defaultProps = {
  reviewData: [],
}

export { Reviews }
