import React from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col } from 'antd'
import { DESCRIPTION_FONT_SIZE, PRIMARY_BG_COLOR } from '../../utils/constants'
import { CategoryScroll } from './category-scrollbar'
import '../style.css'

const MAIN_STYLE = {
  flex: 1,
  overFlow: 'auto',
}

const PARENT_STYLE = {
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'flex-start',
  overflowX: 'scroll',
  padding: '10px 0',
}

const META_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: DESCRIPTION_FONT_SIZE,
}

const CategoryDetail = (props) => {
  const { items, onSelect } = props
  const [activeKey, setActiveKey] = React.useState('All products')
  const containerRef = React.useRef(null)

  const onSelectCategory = React.useCallback(
    (key) => {
      setActiveKey(key)
      onSelect(items?.filter((x) => x.title === key)[0])
    },
    [items, onSelect]
  )

  if (!items?.length) return null

  return (
    <div style={MAIN_STYLE}>
      <Row style={PARENT_STYLE} ref={containerRef}>
        {items.map((item) => (
          <Col>
            <Card
              styles={{
                body: {
                  padding: '16px',
                },
              }}
              key={item.title}
              hoverable
              style={{
                width: 128,
                heigh: 108,
                margin: 5,
                background: PRIMARY_BG_COLOR,
                border: activeKey === item.title ? '1px solid black' : 'none',
              }}
              onClick={() => onSelectCategory(item.title)}
              cover={<img alt={item.title} src={item.image} />}
            >
              <Card.Meta style={META_STYLE} title={item.title} />
            </Card>
          </Col>
        ))}
        <CategoryScroll containerRef={containerRef} />
      </Row>
    </div>
  )
}

CategoryDetail.propTypes = {
  items: PropTypes.object,
  onSelect: PropTypes.func,
}

CategoryDetail.defaultProps = {
  items: {},
  onSelect: () => {},
}

export { CategoryDetail }
