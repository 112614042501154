import galleryImg from '../../assets/catagories/gallery-img.jpg'
import galleryImg2 from '../../assets/catagories/gallery-img2.jpg'
import galleryImg3 from '../../assets/catagories/gallery-img3.jpg'
import reviewImg from '../../assets/catagories/review-img/review-img.jpg'
import reviewImg2 from '../../assets/catagories/review-img/review-img2.jpg'
import reviewImg3 from '../../assets/catagories/review-img/review-img3.jpg'
import reviewImg4 from '../../assets/catagories/review-img/review-img4.jpg'

const galleryData = {
  bigImage: galleryImg,
  smallImages: [galleryImg2, galleryImg3],
}

const reviewData = [
  {
    imgSrc: reviewImg,
    rating: 5,
    date: 'December 20, 2023',
    text: 'Calidad y facilidad de montaje',
    reviewer: 'Jonas from Spain',
  },
  {
    imgSrc: reviewImg2,
    rating: 5,
    date: 'November 20, 2023',
    text: 'Happy with my new beautiful and own designed furniture!',
    reviewer: 'Johanna Schilly from Germany',
  },

  {
    imgSrc: reviewImg3,
    rating: 5,
    date: 'December 20, 2023',
    text: 'Calidad y facilidad de montaje',
    reviewer: 'Jonas from Spain',
  },
  {
    imgSrc: reviewImg4,
    rating: 5,
    date: 'November 20, 2023',
    text: 'Happy with my new beautiful and own designed furniture!',
    reviewer: 'Ana from Spain',
  },
  {
    imgSrc: reviewImg2,
    rating: 5,
    date: 'November 20, 2023',
    text: 'Happy with my new beautiful and own designed furniture!',
    reviewer: 'Ana from Spain',
  },
  {
    imgSrc: reviewImg,
    rating: 5,
    date: 'November 20, 2023',
    text: 'Happy with my new beautiful and own designed furniture!',
    reviewer: 'Ana from Spain',
  },
]

export { galleryData, reviewData }
